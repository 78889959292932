import React from "react";
import "./Footer.css"

const Footer = () => {
    return (
        <div className="Footer-container">
            <span className="Footer-text">©2023 Design - Camila Bosco  \  Development - Lucía Chinni</span>
        </div>
    )
}

export default Footer;