import './App.css';
import Contact from './components/Contact/Contact';
import Bio from "./components/Bio/Bio";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer"
import Header from './components/Header/Header';
import Tour from './components/Tour/Tour';
import CarouselSyd from './components/Carousel/Carousel';
import Footer from './components/Footer';

import CarouselPhone from './components/Album/CarouselPhone';

import Album from './components/Album/Album';
/* import Loading from './components/Loading/Loading';
import { useEffect, useState } from 'react'; */

const App = () => {
/*   const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 3500)
  }) */

  return (
    <div>
        <VideoPlayer />
       <div>
        <Header className="header-app"/>
      <div className='all-components'>
        <Bio />
        <Tour />
        <Contact />
        <CarouselSyd className="pc-carousel"/>
        <Footer />
         {/* <Album />  */}
       </div>
      </div>
    </div>
  )
}


/* function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <h2
          className="App-link"
          target="_blank"
          rel="noopener noreferrer"
        > 
          Sucio y desprolijo mdq
        </h2>
        <Nav/>
      </header>
    </div>
  );
} */

export default App;































