import React from "react";
import ReactPlayer from "react-player";
import Video from "../../assets/sydmixshort.mp4"
import sydlogo from "../../assets/sydlogo.svg";
import "./VideoPlayer.css"

const VideoPlayer = () => {

    return (
        <div>
            <div className='player-wrapper'>
            <div className="logo">
                <img src={sydlogo}/>
            </div>
            <ReactPlayer 
            className="react-player"
            url={Video} 
            playing  
            muted 
            controls 
            height="100%"
            width="100%"
            />
            </div>
        </div>
    )
}

export default VideoPlayer;