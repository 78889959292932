import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import pic1 from "../Album/ImgAlbum/marea1.png";
import pic2 from "../Album/ImgAlbum/marea2.png";
import pic3 from "../Album/ImgAlbum/sinfonica1.png";
import pic4 from "../Album/ImgAlbum/sinfonica2.png";
import pic5 from "../Album/ImgAlbum/sinfonica3.png";
import pic6 from "../Album/ImgAlbum/willyquiroga.png";
import pic7 from "../Album/ImgAlbum/alejandromedina1.png";
import pic8 from "../Album/ImgAlbum/alejandromedina2.png";
import pic9 from "../Album/ImgAlbum/jaf1.png";
import pic10 from "../Album/ImgAlbum/jaf2.png";
import pic11 from "../Album/ImgAlbum/claudiogabis.png";
import pic12 from "../Album/ImgAlbum/boffserafine.png";
import pic13 from "../Album/ImgAlbum/clarin.png";
import pic14 from "../Album/ImgAlbum/grupobrewhouse.png"
import pic15 from "../Album/ImgAlbum/chicho.png"
import pic16 from "../Album/ImgAlbum/gaston.png"
import pic17 from "../Album/ImgAlbum/tira.png"
import "./Carousel.css"


const CarouselSyd = () => {

    let carousel = [
        {
            id:1,
            source: pic1,
            legend:"Presentación en el festival Marea Puerto - Octubre 2022"
        },
        {
            id:2,
            source: pic2,
            legend:"Presentación en el festival Marea Puerto - Octubre 2022"
        },
        {
            id:3,
            source: pic14,
            legend:"Presentación en Brewhouse teloneando a Vox Dei - 2022"
        },
        {
            id:4,
            source: pic15,
            legend:"Chicho Romairone - Presentación en Brewhouse teloneando a Vox Dei - 2022"
        },
        {
            id:5,
            source: pic16,
            legend:"Gastón Arrúa - Presentación en Brewhouse teloneando a Vox Dei - 2022"
        },
        {
            id:6,
            source: pic17,
            legend:"Tira Arseni - Presentación en Brewhouse teloneando a Vox Dei - 2022"
        },
        {
            id:7,
            source: pic3,
            legend:"Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018"
        },
        {
            id:8,
            source: pic4,
            legend:"Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018"
        },
        {
            id:9,
            source: pic5,
            legend:"Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018"
        },
        {
            id:10,
            source: pic6,
            legend:"Junto a Willy Quiroga, bajista de Vox Dei - 2022"
        },
        {
            id:11,
            source: pic7,
            legend:"Junto a Alejandro Medina, bajista de Manal - 2013"
        },
        {
            id:12,
            source: pic8,
            legend:"Junto a Alejandro Medina, bajista de Manal - 2013"
        },
        {
            id:13,
            source: pic9,
            legend:"Junto a JAF - 2014"
        },
        {
            id:14,
            source: pic10,
            legend:"Junto a JAF - 2014"
        },
        {
            id:15,
            source: pic11,
            legend:"Junto a Claudio Gabis, guitarrista de Manal"
        },
        {
            id:16,
            source: pic12,
            legend:"Junto a Boff Serafine, guitarrista de Riff"
        },
        {
            id:17,
            source: pic13,
            legend:"Presentación en el Espacio Clarín de Mar del Plata"
        }
    ]

    return (
        <section id="galeria"  className="Main-slide">
            <h1 className="Slideshow-title">GALERÍA</h1>
            <Carousel className="Carousel-container">
                <div className='menosUno'>
                    <img src={pic1} />
                    <p className="legend">Presentación en el festival Marea Puerto - Octubre 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic2} />
                    <p className="legend">Presentación en el festival Marea Puerto - Octubre 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic14} />
                    <p className="legend">Presentación en Brewhouse teloneando a Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic15} />
                    <p className="legend">Chicho Romairone - Presentación en Brewhouse teloneando a Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic16} />
                    <p className="legend">Gastón Arrúa - Presentación en Brewhouse teloneando a Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic17} />
                    <p className="legend">Tira Arseni - Presentación en Brewhouse teloneando a Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic3} />
                    <p className="legend">Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018</p>
                </div>
                <div className='menosUno'>
                    <img src={pic4} />
                    <p className="legend">Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018</p>
                </div>
                <div className='menosUno'>
                    <img src={pic5} />
                    <p className="legend">Pappo Sinfónico - Junto a la Banda Sinfónica Municipal - 2018</p>
                </div>
                <div className='menosUno'>
                    <img src={pic6} />
                    <p className="legend">Junto a Willy Quiroga, bajista de Vox Dei - 2022</p>
                </div>
                <div className='menosUno'>
                    <img src={pic7} />
                    <p className="legend">Junto a Alejandro Medina, bajista de Manal - 2013</p>
                </div>
                <div className='menosUno'>
                    <img src={pic8} />
                    <p className="legend">Junto a Alejandro Medina, bajista de Manal - 2013</p>
                </div>
                <div className='menosUno'>
                    <img src={pic9} />
                    <p className="legend">Junto a JAF - 2014</p>
                </div>
                <div className='menosUno'>
                    <img src={pic10} />
                    <p className="legend">Junto a JAF - 2014</p>
                </div>
                <div className='menosUno'>
                    <img src={pic11} />
                    <p className="legend">Junto a Claudio Gabis, guitarrista de Manal</p>
                </div>
                <div className='menosUno'>
                    <img src={pic12} />
                    <p className="legend">Junto a Boff Serafine, guitarrista de Riff</p>
                </div>
                <div className='menosUno'>
                    <img src={pic13} />
                    <p className="legend">Presentación en el Espacio Clarín de Mar del Plata</p>
                </div>
            </Carousel>
            <div className="Carousel-phone">
            <div className="Carousel-phone-pics">
                    <div className="Carousel-phone-pics-container">
                        {carousel.map((data, index) => {
                            return (
                                <div key={index} className="Carousel-pic-and-legend-container">
                                    <div className="Carousel-pic-and-legend">
                                        <img className="Carousel-pic" src={data.source} />
                                        <span className="Carousel-legend">{data.legend}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
} 

export default CarouselSyd